import axios from "axios";
import { setInitialState, updateMoreValues } from "../Redux/Actions/AuthAction";
import { store } from "../store";

const $http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

$http.interceptors.response.use(
  (config: any) => config,
  (error: any) => {
    if (
      error?.message?.includes("403") // Block
    ) {
      handleApiError();
    }
    return Promise.reject(error);
  }
);

const handleApiError = async () => {
  const { dispatch } = store;
  await dispatch(updateMoreValues({ ACCESS_TOKEN: "" }));
  await dispatch(setInitialState());
  // await window.location.assign("../");
  setTimeout(()=>{
     window.location.href = '/';
    return Promise.reject();
  },1000)
};

$http.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        const authToken = store.getState()?.AuthReducer?.ACCESS_TOKEN;
        if (authToken && authToken.length > 0) {
          // $http.defaults.headers.common.Authorization = `Bearer ${authToken}`;
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

const setAuthorizationToken = (token: any) => {
  if (token) {
    $http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

let status_code = {
  success: 200,
  successAction: 201,
  notFound: 204,
  badRequest: 400,
  Unauthorized: 401,
  invalid: 400,
  timeout: 408,
  userDelete: 410,
  serverError: 500,
};

const postApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, JSON.stringify(params))
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response);
    });
};

const postUploadApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  const authToken = store.getState()?.AuthReducer?.ACCESS_TOKEN;
  $http
    .post(endPoint, params, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response);
    });
};

const putApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .put(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const postUploadBooks = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, JSON.stringify(params), {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error: any) => {
      errorCallback && errorCallback(error?.response);
    });
};

const getApiCall = (
  endPoint: string,
  paramsData: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .get(endPoint + paramsData, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const getApiCallWithoutToken = (
  endPoint: string,
  paramsData: object,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .get(endPoint + paramsData)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const patchApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .patch(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const deleteApiCall = (
  endPoint: string,
  paramsData: object,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .delete(endPoint, paramsData)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      errorCallback(error.response);
    });
};

const postUploadMedia = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, params, {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

export default {
  postApiCall,
  postUploadApiCall,
  getApiCall,
  getApiCallWithoutToken,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  handleApiError,
  setAuthorizationToken,
  status_code,
  $http,
  postUploadBooks,
};
